@import "abstracts/_abstracts";
.SubscribeBox {
	background: transparent;
	color: var(--colorThemeAccent);

	&-frame {
		display: flex;
		flex-flow: column;
		gap: 1rem;
	}

	&-input {
		display: flex;
		gap: var(--spaceXs);
		flex-wrap: wrap;
		justify-content: flex-end;

		@include media(">xs") {
			flex-wrap: nowrap;
		}

		input {
			background: transparent;
			border: 1px solid var(--colorFooterAccentLight);
			border-radius: 0.5rem;
			color: var(--colorThemeAccent);
			font-size: 1rem;

			@include media(">xs") {
				min-width: 15rem;
			}

			&::-webkit-input-placeholder {
				color: rgba(255, 255, 255, 0.70);
				font-size: 1rem;
				line-height: 1.5;
				font-weight: 400;
			}

			&:focus {
				background-color: transparent;
				border-color: var(--colorThemeAccent);
			}
		}
	}

	&-gdpr {
		> div {
			font-size: 1rem;
			margin-top: 1rem;
		}

		a {
			color: var(--colorThemeAccent);
			font-weight: 700;
			text-decoration: underline;

			@include on-event {
				color: var(--colorBrand);
			}
		}
	}
}